<template>
  <view-item title="客户车辆">
    <template #operation>
      <en-button type="primary" @click="customer.get">查询</en-button>
      <en-button type="primary" @click="operation.addCustomer.click">新增客户</en-button>
      <en-button type="primary" @click="operation.addVehicle.click">新增车辆</en-button>
      <en-dropdown @command="operation.options.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="coupon">发放优惠券</en-dropdown-item>
          <en-dropdown-item v-if="tabs.active === 'customer'" command="sms">发送短信</en-dropdown-item>
          <en-dropdown-item v-if="tabs.active === 'customer'" command="sms-batch">全部发送短信</en-dropdown-item>
          <en-dropdown-item v-if="tabs.active === 'customer'" command="inject">客户导入</en-dropdown-item>
          <en-dropdown-item v-if="tabs.active === 'customer'" command="export">客户导出</en-dropdown-item>
          <en-dropdown-item command="merge">{{ tabs.active === 'customer' ? '客户' : '车辆' }}数据合并</en-dropdown-item>
          <en-dropdown-item v-if="tabs.active === 'customer'" command="batch-discount">批量修改折扣</en-dropdown-item>
          <en-dropdown-item v-if="tabs.active === 'customer'" command="batch-advisor">批量分配客户</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <en-card class="h-full" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="客户" name="customer">
              <table-dynamic
                code="CUSFD"
                :data="customer.data"
                :loading="customer.loading"
                :height="height - 55"
                :config="customer.config"
                pagination
                :paging="customer.paging"
                :method="customer.get"
                show-selectable
                @selection-change="customer.selection.change"
              >
                <template #OPERATION="{ row }: { row: CustomerDto }">
                  <button-ajax v-if="row.wechatUnionId" type="primary" link :method="customer.operation.unbind.click" :params="row">
                    微信解绑
                  </button-ajax>
                  <button-ajax v-else type="primary" link :method="customer.operation.bind.click" :params="row">微信绑定</button-ajax>
                  <button-delete :method="customer.operation.delete.click" :params="row">删除</button-delete>
                </template>

                <template #NAME="{ row }: { row: CustomerDto }">
                  <en-button type="primary" link @click="customer.name.click(row)">{{ row.name }}</en-button>
                </template>
              </table-dynamic>
            </en-tab-pane>
            <en-tab-pane label="车辆" name="vehicle">
              <table-dynamic
                code="VHIEFD"
                :data="vehicle.data"
                :loading="vehicle.loading"
                :height="height - 55"
                pagination
                :paging="vehicle.paging"
                :method="vehicle.get"
                :config="vehicle.config"
                show-selectable
                @selection-change="vehicle.selection.change"
              >
                <template #OPERATION="{ row }: { row: VehicleDto }">
                  <button-delete :method="vehicle.operation.delete.click" :params="row">删除</button-delete>
                </template>

                <template #PLATE_NO="{ row }: { row: VehicleDto }">
                  <en-button type="primary" link @click="vehicle.plateNo.click(row)">{{ row.plateNo }}</en-button>
                </template>
              </table-dynamic>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>

  <en-drawer v-model="customerDetail.visible" :title="customerDetail.form.data.id ? '编辑客户' : '添加客户'" direction="btt" size="80%">
    <flex-box>
      <template #default="{ height }">
        <en-tabs v-model="customerDetail.tabs.active">
          <en-tab-pane label="基本信息" name="customer">
            <en-scrollbar :height="height - 55">
              <customer-form
                :model-value="customerDetail.form.data"
                :loading="customerDetail.form.loading"
                @change="customerDetail.form.change"
                class="grid grid-cols-4 gap-x-6"
              ></customer-form>
            </en-scrollbar>
          </en-tab-pane>

          <en-tab-pane label="车辆信息" name="vehicle">
            <div>
              <en-button type="primary" text @click="customerDetail.form.ownedVehicles.add.click">+ 添加</en-button>
              <en-button
                v-for="(vehicle, index) of customerDetail.form.data.ownedVehicles"
                :type="customerDetail.form.ownedVehicles.activeIndex === index ? 'primary' : 'default'"
                @click="customerDetail.form.ownedVehicles.activeIndex = index"
              >
                {{ vehicle.plateNo || '请选择车牌' }}
              </en-button>
            </div>
            <en-scrollbar :height="height - 87">
              <vehicle-form
                v-if="customerDetail.form.data.ownedVehicles?.[customerDetail.form.ownedVehicles.activeIndex]"
                :model-value="customerDetail.form.data.ownedVehicles?.[customerDetail.form.ownedVehicles.activeIndex]"
                @change="customerDetail.form.ownedVehicles.change"
                :ref="setRefs('vehicleForms')"
                class="grid grid-cols-4 gap-x-6"
              ></vehicle-form>
            </en-scrollbar>
          </en-tab-pane>

          <en-tab-pane label="套餐信息" name="combo">
            <en-table :data="customerDetail.combo.data" :height="height - 55" :loading="customerDetail.combo.loading">
              <en-table-column label="订单编号" prop="serialNo" width="160"></en-table-column>
              <en-table-column label="拼团编号" prop="mallCombo.mallGroupPurchase.serialNo"></en-table-column>
              <en-table-column label="拼团名称" prop="mallCombo.mallGroupPurchase.name"></en-table-column>
              <en-table-column label="拼团类型" prop="mallCombo.mallGroupPurchase.type.message"></en-table-column>
              <en-table-column label="套餐名称" prop="mallCombo.name"></en-table-column>
              <en-table-column label="客户名称" prop="customer.name"></en-table-column>
              <en-table-column label="车牌号码" prop="plateNo"></en-table-column>
              <en-table-column label="手机号码" prop="phoneNo"></en-table-column>
              <en-table-column label="支付方式" prop="source.message"></en-table-column>
              <en-table-column label="销售价格" prop="mallCombo.sellPrice">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['MallComboOrderDto'] }">
                  {{ formatMoney(row.mallCombo.sellPrice) }}
                </template>
              </en-table-column>
              <en-table-column label="团ID" prop="groupPurchaseOrder.serialNo"></en-table-column>
              <en-table-column label="类型">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['MallComboOrderDto'] }">
                  {{ row.type?.code === 'Y' ? '开团' : '参团' }}
                </template>
              </en-table-column>
              <en-table-column label="拼团状态">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['MallComboOrderDto'] }">
                  {{
                    row.groupPurchaseOrder
                      ? `${row.groupPurchaseOrder.status.message}(${row.groupPurchaseOrder.groupCount}/${row.groupPurchaseOrder.numberOfPeople})`
                      : ''
                  }}
                </template>
              </en-table-column>
              <en-table-column label="订单价格" prop="amount">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['MallComboOrderDto'] }">{{ formatMoney(row.amount) }}</template>
              </en-table-column>
              <en-table-column label="成团返利" prop="rebateAmount">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['MallComboOrderDto'] }">
                  {{ formatMoney(row.rebateAmount + row.redpackAmount) }}
                </template>
              </en-table-column>
              <en-table-column label="实际收益" prop="realAmount">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['MallComboOrderDto'] }">
                  {{ formatMoney(row.paymentAmount - row.rebateAmount - row.redpackAmount) }}
                </template>
              </en-table-column>
              <en-table-column label="已服务次数" prop="serveTimes" width="130"></en-table-column>
              <en-table-column label="状态" prop="status.message"></en-table-column>
              <en-table-column label="微信名称" prop="wechatUser.name"></en-table-column>
              <en-table-column label="订单日期" prop="datetime">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['MallComboOrderDto'] }">{{ formatDate(row.datetime) }}</template>
              </en-table-column>
              <en-table-column label="备注" prop="remark"></en-table-column>
            </en-table>
          </en-tab-pane>

          <en-tab-pane label="剩余卡券" name="coupon">
            <en-table
              :data="customerDetail.coupon.data"
              :height="height - 55"
              :loading="customerDetail.coupon.loading"
              pagination
              :paging="customerDetail.coupon.paging"
              :method="customerDetail.coupon.get"
            >
              <en-table-column label="券码" prop="serialNo" width="150"></en-table-column>
              <en-table-column label="卡券类别" prop="ownerType.message"></en-table-column>
              <en-table-column label="卡券编号" prop="mallCoupon.serialNo" width="150"></en-table-column>
              <en-table-column label="卡券名称" prop="mallCoupon.name"></en-table-column>
              <en-table-column label="卡券类型" prop="mallCoupon.type.message" width="100"></en-table-column>
              <en-table-column label="面值" prop="mallCoupon.faceValue">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['MallCouponInstanceDto'] }">
                  {{ formatMoney(row.mallCoupon.faceValue) }}
                </template>
              </en-table-column>
              <en-table-column label="有效期" width="180">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['MallCouponInstanceDto'] }">
                  {{ `${row.startDate}-${row.endDate}` }}
                </template>
              </en-table-column>
              <en-table-column label="会员卡号" prop="customer.membershipCardNo" width="150"></en-table-column>
              <en-table-column label="状态" prop="status.message"></en-table-column>
              <en-table-column label="领取日期" prop="datetime" width="120">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['MallCouponInstanceDto'] }">
                  {{ formatDate(row.datetime, true) }}
                </template>
              </en-table-column>
              <en-table-column label="领取人" prop="wechatUser.name"></en-table-column>
              <en-table-column label="车牌号码" prop="plateNo"></en-table-column>
              <en-table-column label="电话" prop="mallComboOrder.phoneNo"></en-table-column>
              <en-table-column label="支付方式" prop="mallComboOrder.source.message"></en-table-column>
              <en-table-column label="客户名称" prop="customer.name"></en-table-column>
              <en-table-column label="客户电话" prop="customer.cellphone" width="150"></en-table-column>
              <en-table-column label="活动编号" prop="mallComboOrder.mallCombo.mallGroupPurchase.serialNo"></en-table-column>
              <en-table-column label="活动名称" prop="mallComboOrder.mallCombo.mallGroupPurchase.name"></en-table-column>
            </en-table>
          </en-tab-pane>

          <en-tab-pane label="综合分析" name="analysis">
            <en-scrollbar :height="height - 55" view-class="flex flex-col gap-2">
              <en-table :data="customerDetail.analysis.data" :loading="customerDetail.analysis.loading">
                <en-table-column label="消费次数" prop="consumeCount"></en-table-column>
                <en-table-column label="累计消费" prop="consumeAmount"></en-table-column>
                <en-table-column label="客单价" prop="consumeAmountAverage">
                  <template #default="{ row }: { row: EnocloudAnalysisDefinitions['AnalysisCustomerDto'] }">
                    {{ formatNumber(row.consumeAmountAverage, 2) }}
                  </template>
                </en-table-column>
                <en-table-column label="最近消费" prop="recentConsumeDays"></en-table-column>
                <en-table-column label="消费周期" prop="consumeCycle">
                  <template #default="{ row }: { row: EnocloudAnalysisDefinitions['AnalysisCustomerDto'] }">
                    {{ formatNumber(row.consumeCycle, 1) }}
                  </template>
                </en-table-column>
                <en-table-column label="生命周期" prop="type.message"></en-table-column>
                <en-table-column label="工时费" prop="serviceSummary.maintenanceAmountAfterDiscount">
                  <template #default="{ row }: { row: EnocloudAnalysisDefinitions['AnalysisCustomerDto'] }">
                    {{ formatNumber(row.serviceSummary?.maintenanceAmountAfterDiscount, 2) }}
                  </template>
                </en-table-column>
                <en-table-column label="配件费" prop="serviceSummary.goodsAmountAfterDiscount">
                  <template #default="{ row }: { row: EnocloudAnalysisDefinitions['AnalysisCustomerDto'] }">
                    {{ formatNumber(row.serviceSummary?.goodsAmountAfterDiscount, 2) }}
                  </template>
                </en-table-column>
                <en-table-column label="应收款" prop="serviceSummary.serviceReceivableAmount">
                  <template #default="{ row }: { row: EnocloudAnalysisDefinitions['AnalysisCustomerDto'] }">
                    {{ formatNumber(row.serviceSummary?.serviceReceivableAmount, 2) }}
                  </template>
                </en-table-column>
                <en-table-column label="工单毛利" prop="serviceSummary.orderProfit">
                  <template #default="{ row }: { row: EnocloudAnalysisDefinitions['AnalysisCustomerDto'] }">
                    {{ formatNumber(row.serviceSummary?.orderProfit, 2) }}
                  </template>
                </en-table-column>
              </en-table>
              <en-table :data="customerDetail.analysis.data[0]?.vehicleSummaries" :loading="customerDetail.analysis.loading">
                <en-table-column label="车牌号" prop="plateNo"></en-table-column>
                <en-table-column label="工时费" prop="maintenanceAmountAfterDiscount">
                  <template #default="{ row }: { row: EnocloudAnalysisDefinitions['ServiceQueryDto'] }">
                    {{ formatNumber(row.maintenanceAmountAfterDiscount, 2) }}
                  </template>
                </en-table-column>
                <en-table-column label="配件费" prop="goodsAmountAfterDiscount">
                  <template #default="{ row }: { row: EnocloudAnalysisDefinitions['ServiceQueryDto'] }">
                    {{ formatNumber(row.goodsAmountAfterDiscount, 2) }}
                  </template>
                </en-table-column>
                <en-table-column label="应收款" prop="serviceReceivableAmount">
                  <template #default="{ row }: { row: EnocloudAnalysisDefinitions['ServiceQueryDto'] }">
                    {{ formatNumber(row.serviceReceivableAmount, 2) }}
                  </template>
                </en-table-column>
                <en-table-column label="工单毛利" prop="orderProfit">
                  <template #default="{ row }: { row: EnocloudAnalysisDefinitions['ServiceQueryDto'] }">
                    {{ formatNumber(row.orderProfit, 2) }}
                  </template>
                </en-table-column>
                <en-table-column label="客单价" prop="averageValue">
                  <template #default="{ row }: { row: EnocloudAnalysisDefinitions['ServiceQueryDto'] }">
                    {{ formatNumber(row.averageValue, 2) }}
                  </template>
                </en-table-column>
              </en-table>
            </en-scrollbar>
          </en-tab-pane>

          <en-tab-pane label="消费记录" name="receipt">
            <table-dynamic
              code="CCSRECFLD"
              :data="customerDetail.receipt.data"
              :loading="customerDetail.receipt.loading"
              :height="height - 55"
              :paging="customerDetail.receipt.paging"
              pagination
              :method="customerDetail.receipt.get"
            ></table-dynamic>
          </en-tab-pane>

          <en-tab-pane label="车况报告" name="report">
            <en-table
              :data="customerDetail.report.data"
              :loading="customerDetail.report.loading"
              :height="height - 55"
              :paging="customerDetail.report.paging"
              pagination
              :method="customerDetail.report.get"
            >
              <en-table-column label="单号" prop="serialNo">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionReportDto'] }">
                  <en-button text type="primary">{{ row.serialNo }}</en-button>
                </template>
              </en-table-column>
              <en-table-column label="车牌号" prop="vehicle.plateNo"></en-table-column>
              <en-table-column label="车主" prop="vehicle.owner.name"></en-table-column>
              <en-table-column label="手机号" prop="vehicle.owner.cellphone"></en-table-column>
              <en-table-column label="车架号" prop="vehicle.vin"></en-table-column>
              <en-table-column label="发动机号" prop="vehicle.engineNumber"></en-table-column>
              <en-table-column label="车品牌">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionReportDto'] }">
                  {{ row.vehicle?.vehicleSpec.join(',') }}
                </template>
              </en-table-column>
              <en-table-column label="检测方案" prop="inspectionPlan.name"></en-table-column>
              <en-table-column label="报告状态" prop="status.message"></en-table-column>
              <en-table-column label="检测人" prop="inspector.name"></en-table-column>
              <en-table-column label="检测日期" prop="inspectDate">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionReportDto'] }">
                  {{ formatDate(row.inspectDate) }}
                </template>
              </en-table-column>
            </en-table>
          </en-tab-pane>
        </en-tabs>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="customerDetail.footer.cancel.click">取消</en-button>
      <en-button v-if="customerDetail.form.data.id" type="primary" @click="customerDetail.footer.logs.click">信息日志</en-button>
      <button-ajax :method="customerDetail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>

  <en-drawer v-model="vehicleDetail.visible" :title="vehicleDetail.form.data.id ? '编辑车辆' : '添加车辆'" direction="btt" size="80%">
    <flex-box>
      <template #default="{ height }">
        <en-scrollbar :height="height">
          <vehicle-form
            v-model="vehicleDetail.form.data"
            :loading="vehicleDetail.form.loading"
            :ref="setRef('vehicleDetailForm')"
            @change="vehicleDetail.form.change"
            class="grid grid-cols-4 gap-x-6"
          ></vehicle-form>
        </en-scrollbar>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="vehicleDetail.footer.cancel.click">取消</en-button>
      <en-button v-if="vehicleDetail.form.data.id" type="primary" @click="vehicleDetail.footer.logs.click">信息日志</en-button>
      <button-ajax :method="vehicleDetail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>

  <en-drawer v-model="logs.visible" title="信息日志" size="638">
    <flex-box>
      <template #default="{ height }">
        <en-table :data="logs.data" :height="height" :show-header="false">
          <en-table-column>
            <template #default="{ row }: { row: EnocloudCommonDefinitions['DocumentLogDto'] }">
              <div class="flex items-center gap-2">
                <img src="https://resource.enoch-car.com/enocloud/assets/img/default-avatar.png" class="w-10 h-10 rounded-10" />
                <span>{{ row.operator?.name }}</span>
                <span>{{ row.message }}</span>
                <span>{{ formatDate(row.datetime, true) }}</span>
              </div>
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="logs.footer.cancel.click">取消</en-button>
    </template>
  </en-drawer>

  <en-drawer v-model="merge.visible" :title="(merge.form.data.type === 'customer' ? '客户' : '车辆') + '数据合并'">
    <en-form :model="merge.form.data" :rules="merge.form.rules" :ref="setRef('mergeForm')">
      <template v-if="merge.form.data.type === 'customer'">
        <en-form-item label="已选择客户数">{{ merge.form.data.customers.length }}</en-form-item>
        <en-form-item label="保留客户" prop="customer.id">
          <select-maintain
            v-model="merge.form.data.customer"
            :options="merge.form.data.customers"
            :props="{ label: (option) => `${option.name}(${option.cellphone})`, value: '' }"
            value-key="id"
          ></select-maintain>
        </en-form-item>
        <en-form-item label="说明">
          <span class="text-sm text-red-500">客户数据合并：选择保留客户后，会把其他的客户数据合并到保留客户数据名下，注意数据合并后将无法退回！</span>
        </en-form-item>
      </template>

      <template v-if="merge.form.data.type === 'vehicle'">
        <en-form-item label="已选择车辆数">{{ merge.form.data.vehicles.length }}</en-form-item>
        <en-form-item label="保留车牌" prop="vehicle.id">
          <select-maintain
            v-model="merge.form.data.vehicle"
            :options="merge.form.data.vehicles"
            :props="{ label: 'plateNo', value: '' }"
            value-key="id"
          ></select-maintain>
        </en-form-item>
        <en-form-item label="说明">
          <span class="text-sm text-red-500">车辆数据合并：选择保留车牌后，会把其他的车牌数据合并到保留车牌数据名下，注意数据合并后将无法退回！</span>
        </en-form-item>
      </template>
    </en-form>

    <template #footer>
      <en-button @click="merge.footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="merge.footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>

  <en-drawer v-model="batch.visible" title="批量修改折扣">
    <en-form :model="batch.form.data" :rules="batch.form.rules" :ref="setRef('batchForm')">
      <en-form-item label="已选择客户数">{{ batch.form.data.customers.length }}</en-form-item>
      <template v-if="batch.type === 'discount'">
        <en-form-item label="工时折扣率" prop="serviceMaintenanceDiscountRate">
          <div class="w-full flex items-center">
            <en-input-number v-model="batch.form.data.serviceMaintenanceDiscountRate" :min="0" :max="1" class="flex-1"></en-input-number>
            <span>{{ formatNumber(batch.form.data.serviceMaintenanceDiscountRate * 100) }}%</span>
          </div>
        </en-form-item>
        <en-form-item label="配件折扣率" prop="serviceGoodsDiscountRate">
          <div class="w-full flex items-center">
            <en-input-number v-model="batch.form.data.serviceGoodsDiscountRate" :min="0" :max="1" class="flex-1"></en-input-number>
            <span>{{ formatNumber(batch.form.data.serviceGoodsDiscountRate * 100) }}%</span>
          </div>
        </en-form-item>
      </template>
      <template v-if="batch.type === 'advisor'">
        <en-form-item label="服务顾问" prop="serviceAdvisor.id">
          <select-maintain
            v-model="batch.form.data.serviceAdvisor"
            :ajax="{
              action: 'GET /enocloud/common/user',
              params: (params, value) => {
                params.payload = { name: value, includingDestroyed: true, advisorFirst: true }
              }
            }"
            :props="{ label: 'name', value: '' }"
            value-key="id"
            remote
            class="w-full"
          ></select-maintain>
        </en-form-item>
        <en-form-item label="说明">
          <span class="text-sm text-red-500">分配客户完成后, 系统会将最新的服务顾问替换原来的服务顾问,请谨慎操作。</span>
        </en-form-item>
      </template>
    </en-form>

    <template #footer>
      <en-button @click="batch.footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="batch.footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>

  <en-drawer v-model="syncing.visible" title="客户导入" direction="btt" size="80%">
    <flex-box>
      <template #default="{ height }">
        <en-table :data="syncing.table.data" :height="height" :row-style="syncing.table.rowStyle">
          <en-table-column label="车主姓名(必填)" prop="name" width="120"></en-table-column>
          <en-table-column label="手机号码(必填)" prop="cellphone" width="120"></en-table-column>
          <en-table-column label="车牌号(必填)" width="120">
            <template #default="{ row }: { row: CustomerDto }">
              {{ row.ownedVehicles[0].plateNo }}
            </template>
          </en-table-column>
          <en-table-column label="车品牌(必填)" width="120">
            <template #default="{ row }: { row: CustomerDto }">
              {{ row.ownedVehicles[0].vehicleSpec }}
            </template>
          </en-table-column>
          <en-table-column label="VIN(必填)" width="120">
            <template #default="{ row }: { row: CustomerDto }">
              {{ row.ownedVehicles[0].vin }}
            </template>
          </en-table-column>
          <en-table-column label="当前里程数" prop="gender.message">
            <template #default="{ row }: { row: CustomerDto }">
              {{ row.ownedVehicles[0].currentMiles }}
            </template>
          </en-table-column>
          <en-table-column label="服务顾问" prop="serviceAdvisor.name" width="120"></en-table-column>
          <en-table-column label="驾照类型" prop="driverLicenceType.message" width="120"></en-table-column>
          <en-table-column label="生日" prop="dob" width="120"></en-table-column>
          <en-table-column label="地址" prop="address"></en-table-column>
          <en-table-column label="座机" prop="cellphone"></en-table-column>
          <en-table-column label="车辆颜色">
            <template #default="{ row }: { row: CustomerDto }">
              {{ row.ownedVehicles[0].color }}
            </template>
          </en-table-column>
          <en-table-column label="车辆种类" width="150">
            <template #default="{ row }: { row: CustomerDto }">
              {{ row.ownedVehicles[0].type && row.ownedVehicles[0].type.name }}
            </template>
          </en-table-column>
          <en-table-column label="排量" prop="type.message">
            <template #default="{ row }: { row: CustomerDto }">
              {{ row.ownedVehicles[0].outputVolume }}
            </template>
          </en-table-column>
          <en-table-column label="是否涡轮增压" width="150">
            <template #default="{ row }: { row: CustomerDto }">
              {{ row.ownedVehicles[0].turboCharged && row.ownedVehicles[0].turboCharged.message }}
            </template>
          </en-table-column>
          <en-table-column label="自动/手动挡(A/M)" prop="type.message">
            <template #default="{ row }: { row: CustomerDto }">
              {{ row.ownedVehicles[0].gearboxType && row.ownedVehicles[0].gearboxType.message }}
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="syncing.footer.cancel.click">取消</en-button>
      <en-button @click="syncing.footer.download.click">下载导入模版</en-button>
      <en-upload action="/enocloud/common/customer/import" :on-success="syncing.footer.upload.success" class="mx-5">
        <en-button>选择导入文件</en-button>
      </en-upload>
      <button-ajax :method="syncing.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>

  <short-message-template v-model="sms.visible" :data="sms.data"></short-message-template>

  <send-coupon
    v-model="coupon.visible"
    :type="tabs.active"
    :options="coupon.options"
    :props="tabs.active === 'customer' ? { name: 'name', id: 'id' } : { name: 'plateNo', id: 'id' }"
  ></send-coupon>
</template>

<script lang="ts">
import { h } from 'vue'
import { map, uniqBy } from 'lodash-es'
import { EnMessage, EnMessageBox } from '@enocloud/components'
import { CustomerForm, ShortMessageTemplate, VehicleForm, SendCoupon } from '@enocloud/business'

import type { UploadFile } from '@enocloud/components'

type VehicleDto = EnocloudCommonDefinitions['VehicleDto']
type VehicleQueryDto = EnocloudCommonDefinitions['VehicleQueryDto']
type CustomerDto = EnocloudCommonDefinitions['CustomerDto']
type CustomerQueryDto = EnocloudCommonDefinitions['CustomerQueryDto']

export default factory({
  page: true,

  components: { CustomerForm, VehicleForm, ShortMessageTemplate, SendCoupon },

  config: {
    children: {
      operation: {
        children: {
          addCustomer: {
            click() {
              this.customerDetail.form.init()
              this.customerDetail.form.data.serviceMaintenanceDiscountRate = 1
              this.customerDetail.form.data.serviceGoodsDiscountRate = 1
              this.customerDetail.visible = true
            }
          },
          addVehicle: {
            click() {
              this.vehicleDetail.form.init()
              this.vehicleDetail.visible = true
            }
          },
          export: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/customer/export'
              }
            }
          },
          options: {
            async command(option: string) {
              const { active } = this.tabs
              const activeName = active === 'customer' ? '客户' : '车辆'
              switch (option) {
                case 'coupon':
                  if (!this[active].selection.data.length) return EnMessage.warning(`请选择${activeName}`)
                  this.coupon.options = this[active].selection.data
                  this.coupon.visible = true
                  break
                case 'sms':
                  if (!this.customer.selection.data.length) return EnMessage.warning(`请选择${activeName}`)
                  this.sms.data = this.customer.selection.data
                  this.sms.visible = true
                  break
                case 'sms-batch':
                  this.sms.data = this.customer.data
                  this.sms.visible = true
                  break
                case 'merge':
                  if (active === 'customer') {
                    if (this.customer.selection.data.length < 2) return EnMessage.warning('请至少选择2条数据')
                    if (uniqBy(this.customer.selection.data, 'name').length !== 1 || uniqBy(this.customer.selection.data, 'cellphone').length !== 1) {
                      return EnMessage.warning('选择的客户必须是客户手机号或名称一致才可合并！')
                    }
                  } else {
                    if (this.vehicle.selection.data.length < 2) return EnMessage.warning('请至少选择2条数据')
                    const noOwners = this.vehicle.selection.data.filter((item) => item.ownerName)
                    if (noOwners.length) {
                      return EnMessageBox({
                        title: '提示',
                        message: h('div', { class: 'flex flex-col text-red-500' }, [
                          h('span', '车牌号无车主不可进行合并操作！'),
                          h('span', map(this.vehicle.selection.data, 'plateNo').join(','))
                        ])
                      })
                    } else {
                      if (
                        uniqBy(this.vehicle.selection.data, 'ownerName').length !== 1 ||
                        uniqBy(this.vehicle.selection.data, 'cellphone').length !== 1
                      ) {
                        return EnMessage.warning('选择的客户必须是客户手机号或名称一致才可合并！')
                      }
                    }
                  }
                  this.merge.form.init()
                  this.merge.form.data.type = active
                  this.merge.form.data.customers = uniqBy(this.customer.selection.data, 'id') as unknown as CustomerDto[]
                  this.merge.form.data.vehicles = uniqBy(this.vehicle.selection.data, 'id') as unknown as VehicleDto[]
                  this.merge.visible = true
                  break
                case 'batch-discount':
                case 'batch-advisor':
                  this.batch.type = option.split('-')[1]
                  this.batch.form.init()
                  this.batch.form.data.customers = this.customer.selection.data as unknown as CustomerDto[]
                  this.batch.visible = true
                  break
                case 'export':
                  await this.operation.export.get()
                  this.store.openDownload()
                case 'inject':
                  this.syncing.table.data = []
                  this.syncing.visible = true
                  break
              }
            }
          }
        }
      },
      tabs: {
        active: 'customer' as 'customer' | 'vehicle'
      },
      customer: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/customer/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { sortedBy: 'CREATED_DATE_desc' }
            }
          },
          unbind: {
            action: 'POST /enocloud/common/customer/:customerId/wechat/unbind'
          },
          bind: {
            action: 'GET /enocloud/common/customer/:customerId/wechat/bind/url'
          },
          delete: {
            action: 'DELETE /enocloud/common/customer/:customerId'
          }
        },
        config: {
          NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'names',
                props: {
                  allowCreate: true,
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, rsexp: 'name' })
                  },
                  props: { label: 'name', value: 'name' },
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          CELLPHONE: { header: { filter: { type: 'text', field: 'cellphone' } } },
          VEHICLE: { header: { filter: { type: 'text', field: 'plateNo' } } },
          TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'type',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params, value) => (params.paths = ['CUSTTYPE'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SALESMAN_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params, value) => (params.paths = ['CLKTYPE'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          TAGS: { header: { filter: { type: 'text', field: 'tag' } } },
          CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerCategoryNames',
                props: {
                  allowCreate: true,
                  multiple: true,
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          COMMENT: { header: { filter: { type: 'text', field: 'comment' } } },
          BIND: {
            header: {
              filter: {
                type: 'select',
                field: 'bind',
                props: {
                  options: [
                    { message: '未绑定', code: 'N' },
                    { message: '已绑定', code: 'Y' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          }
        },
        children: {
          selection: {
            data: [] as CustomerQueryDto[],
            change(rows: CustomerQueryDto[]) {
              this.customer.selection.data = rows
            }
          },
          operation: {
            unbind: {
              async click(row: CustomerDto) {
                await this.customer.unbind({ paths: [row.id] })
                return this.customer.get()
              }
            },
            bind: {
              async click(row: CustomerDto) {
                const res = await this.customer.bind({ paths: [row.id] })
                return EnMessageBox({
                  title: row.name,
                  center: true,
                  showConfirmButton: false,
                  message: h('img', { src: res.data[0], width: 300, height: 300, class: 'm-auto' })
                })
              }
            },
            delete: {
              async click(row: CustomerDto) {
                await this.customer.delete({ paths: [row.id] })
                return this.customer.get()
              }
            }
          },
          name: {
            async click(row: CustomerDto) {
              this.customerDetail.form.init()
              this.customerDetail.form.data.id = row.id
              this.customerDetail.form.ownedVehicles.activeIndex = 0
              this.customerDetail.visible = true
              await this.customerDetail.form.get()
              this.customerDetail.combo.get()
              this.customerDetail.coupon.get()
              this.customerDetail.analysis.get()
              this.customerDetail.receipt.get()
              this.customerDetail.report.get()
            }
          }
        }
      },
      vehicle: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/query',
            data: 'array',
            loading: true,
            pagination: true
          },
          delete: {
            action: 'DELETE /enocloud/common/vehicle/:vehicleId'
          }
        },
        children: {
          selection: {
            data: [] as VehicleQueryDto[],
            change(rows: VehicleQueryDto[]) {
              this.vehicle.selection.data = rows
            }
          },
          operation: {
            delete: {
              async click(row: VehicleDto) {
                await this.vehicle.delete({ paths: [row.id] })
                return this.vehicle.get()
              }
            }
          },
          plateNo: {
            click(row: VehicleDto) {
              this.vehicleDetail.form.init()
              this.vehicleDetail.form.data.id = row.id
              this.vehicleDetail.form.get()
              this.vehicleDetail.visible = true
            }
          }
        },
        config: {
          PLATE_NO: { header: { filter: { type: 'text', field: 'plateNo' } } }
        }
      },
      customerDetail: {
        visible: false,
        children: {
          tabs: {
            active: 'customer'
          },
          form: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/customer/:customerId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.customerDetail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/customer',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.customerDetail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/customer',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.customerDetail.form.data
                }
              }
            },
            change(value: CustomerDto) {
              Object.assign(this.customerDetail.form.data, value)
            },
            children: {
              ownedVehicles: {
                activeIndex: 0,
                change(value: VehicleDto) {
                  this.customerDetail.form.data.ownedVehicles ??= []
                  const current = this.customerDetail.form.data.ownedVehicles[this.customerDetail.form.ownedVehicles.activeIndex]
                  if (current) {
                    Object.assign(current, value)
                  } else {
                    this.customerDetail.form.data.ownedVehicles.push(value)
                  }
                },
                add: {
                  click() {
                    this.customerDetail.form.data.ownedVehicles ??= []
                    if (this.customerDetail.form.data.ownedVehicles.filter((item) => !item.plateNo).length) return
                    this.customerDetail.form.data.ownedVehicles.push({
                      driverLicenceFirstUrls: [],
                      driverLicenceSecondUrls: [],
                      driverLicenceThirdUrls: [],
                      logs: [],
                      nameplateUrls: [],
                      vehicleAccountHistories: [],
                      vehicleSpec: []
                    })
                    this.customerDetail.form.ownedVehicles.activeIndex = this.customerDetail.form.data.ownedVehicles?.length - 1
                  }
                }
              }
            }
          },
          combo: {
            ajax: {
              get: {
                action: 'GET /enocloud/mall/combo/order',
                data: 'array',
                loading: true,
                params(params) {
                  params.payload = { customerId: this.customerDetail.form.data.id }
                }
              }
            }
          },
          coupon: {
            ajax: {
              get: {
                action: 'GET /enocloud/mall/coupon/instance',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = { customerId: this.customerDetail.form.data.id, statusCode: 'A' }
                }
              }
            }
          },
          analysis: {
            ajax: {
              get: {
                action: 'GET /enocloud/analysis/synthesize/:customerId',
                data: 'array',
                loading: true,
                params(params) {
                  params.paths = [this.customerDetail.form.data.id]
                }
              }
            }
          },
          receipt: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/receivable/query',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = { payerId: this.customerDetail.form.data.id, typeCode: 'SRV', restrictBranch: 'N' }
                }
              }
            }
          },
          report: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/vehicle/inspection/report',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = { ownerName: this.customerDetail.form.data.name, statusCode: 'C' }
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.customerDetail.visible = false
              }
            },
            confirm: {
              async click() {
                await Promise.all((this.refs.vehicleForms ?? []).map((item: any) => item.validate()))
                const vehicles = [...this.customerDetail.form.data.ownedVehicles]
                const res = await this.customerDetail.form[this.customerDetail.form.data.id ? 'update' : 'submit']()
                for (const vehicle of vehicles) {
                  vehicle.owner ??= this.customerDetail.form.data
                  vehicle.owner = Object.assign(vehicle.owner, { id: res.data[0] || this.customerDetail.form.data.id })
                  await this.ajax(vehicle.id ? 'PUT /enocloud/common/vehicle' : 'POST /enocloud/common/vehicle', { payload: vehicle })
                }
                this.customer.get()
                this.vehicle.get()
                this.customerDetail.visible = false
              }
            },
            logs: {
              click() {
                this.logs.data = this.customerDetail.form.data.logs
                this.logs.visible = true
              }
            }
          }
        }
      },
      vehicleDetail: {
        visible: false,
        children: {
          form: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/vehicle/:vehicleId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.vehicleDetail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/vehicle',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.vehicleDetail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/vehicle',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.vehicleDetail.form.data
                }
              }
            },
            change(value: VehicleDto) {
              Object.assign(this.vehicleDetail.form.data, value)
            }
          },
          footer: {
            cancel: {
              click() {
                this.vehicleDetail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.vehicleDetail.form[this.vehicleDetail.form.data.id ? 'update' : 'submit']()
                this.vehicle.get()
                this.vehicleDetail.visible = false
              }
            },
            logs: {
              click() {
                this.logs.data = this.vehicleDetail.form.data.logs
                this.logs.visible = true
              }
            }
          }
        }
      },
      logs: {
        visible: false,
        data: [] as EnocloudCommonDefinitions['DocumentLogDto'][],
        children: {
          footer: {
            cancel: {
              click() {
                this.logs.visible = false
              }
            }
          }
        }
      },
      coupon: {
        visible: false,
        options: [] as any[]
      },
      sms: {
        visible: false,
        data: [] as CustomerQueryDto[]
      },
      merge: {
        visible: false,
        children: {
          form: {
            data: {
              type: 'customer' as 'customer' | 'vehicle',
              customer: {} as CustomerDto,
              customers: [] as CustomerDto[],
              vehicle: {} as VehicleDto,
              vehicles: [] as VehicleDto[]
            },
            rules: {
              'customer.id': [{ required: true, message: '请选择客户' }],
              'vehicle.id': [{ required: true, message: '请选择车牌' }]
            },
            ajax: {
              customer: {
                action: 'POST /enocloud/common/customer/:customerId/merge',
                validate: true,
                params(params) {
                  params.paths = [this.merge.form.data.customer.id]
                  params.data = this.merge.form.data.customers
                }
              },
              vehicle: {
                action: 'POST /enocloud/common/vehicle/:vehicleId/merge',
                validate: true,
                params(params) {
                  params.paths = [this.merge.form.data.vehicle.id]
                  params.data = this.merge.form.data.vehicles
                }
              }
            }
          },
          footer: {
            confirm: {
              async click() {
                await this.merge.form[this.merge.form.data.type]()
                this[this.merge.form.data.type].get()
                this.merge.visible = false
              }
            },
            cancel: {
              click() {
                this.merge.visible = false
              }
            }
          }
        }
      },
      batch: {
        visible: false,
        type: 'discount',
        children: {
          form: {
            data: {
              serviceGoodsDiscountRate: 0,
              serviceMaintenanceDiscountRate: 0,
              serviceAdvisor: {} as EnocloudCommonDefinitions['UserDto'],
              customers: [] as CustomerDto[]
            },
            rules: {
              serviceGoodsDiscountRate: [{ required: true, message: '请填写配件折扣率' }],
              serviceMaintenanceDiscountRate: [{ required: true, message: '请填写工时折扣率' }],
              'serviceAdvisor.id': [{ required: true, message: '请选择服务顾问' }]
            },
            ajax: {
              submit: {
                action: 'PUT /enocloud/common/customer/group',
                validate: true,
                params(params) {
                  params.data = this.batch.form.data.customers.map((item) => {
                    const res = {} as CustomerDto
                    res.id = item.id
                    if (this.batch.type === 'discount') {
                      res.serviceGoodsDiscountRate = this.batch.form.data.serviceGoodsDiscountRate
                      res.serviceMaintenanceDiscountRate = this.batch.form.data.serviceMaintenanceDiscountRate
                    } else if (this.batch.type === 'advisor') {
                      res.serviceAdvisor = this.batch.form.data.serviceAdvisor
                    }
                    return res
                  })
                }
              }
            }
          },
          footer: {
            confirm: {
              async click() {
                await this.batch.form.submit()
                this.customer.get()
                this.batch.visible = false
              }
            },
            cancel: {
              click() {
                this.batch.visible = false
              }
            }
          }
        }
      },
      syncing: {
        visible: false,
        children: {
          table: {
            data: [] as CustomerDto[],
            ajax: {
              submit: {
                action: 'POST /enocloud/common/customer/initial',
                params(params) {
                  params.data = this.syncing.table.data.filter((item) => !item.existed?.value)
                }
              }
            },
            rowStyle({ row }: { row: CustomerDto }) {
              if (row.existed?.value) return { color: 'red' }
            }
          },
          footer: {
            cancel: {
              click() {
                this.batch.visible = false
              }
            },
            download: {
              click() {
                window.open(`/enocloud/common/customer/download`)
              }
            },
            upload: {
              success(uploadFile: UploadFile, response: { data: EnocloudCommonDefinitions['CustomerDto'][] }) {
                this.syncing.table.data.push(...response.data)
              }
            },
            confirm: {
              async click() {
                await this.syncing.table.submit()
                this.customer.get()
                this.syncing.visible = false
              }
            }
          }
        }
      }
    }
  },

  async mounted() {
    this.customer.get()
    this.vehicle.get()
  }
})
</script>
